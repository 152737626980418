import React from "react"
import SectionCurrentSponsors from "../../components/sections/sectionSponsorCurrent"
import HeadingPastSpnsrs from "../../images/svg/inline-svg/heading_past-spnsrs.svg"
import HeadingPastSpnsrsMobile from "../../images/svg/inline-svg/heading_past-spnsrs_mobile.svg"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SectionHeroPage from "../../components/sections/heroPage"
import HeadingSponsorsAndExhibit from "../../images/svg/inline-svg/heading_sponsor-and-exhibit.svg"
import HeadingSponsorsTypes from "../../images/svg/inline-svg/heading_spnsr-types.svg"
import HeadingSponsorMobile from "../../images/svg/inline-svg/heading_spnsr-types_mobile.svg"
import SectionMediaHalf from "../../components/sections/mediaHalf"
import SectionTopFour from "../../components/sections/sectionTopFour"
import SectionSponsorTypes from "../../components/sections/sectionSponsorTypes"
import SectionPastSponsors from "../../components/sections/sectionSponsorPast"

const SponsorsPage = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              bizzaboEventId
              year
              sponsorshipLink
            }
          }
          headerSponsor: file(
            relativePath: { eq: "headers/hero-sponsor.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          headerImageMobile: file(
            relativePath: { eq: "headers/hero-sponsor-mobile.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 624) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          boothCrowd: file(relativePath: { eq: "MeetYourNextBig.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          joinTheTeam: file(relativePath: { eq: "ReadyToJoin.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 712) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const headerSponsor = data.headerSponsor.childImageSharp.fluid
        const headerImageMobile = data.headerImageMobile.childImageSharp.fluid
        const boothCrowd = data.boothCrowd.childImageSharp.fluid
        const joinTheTeam = data.joinTheTeam.childImageSharp.fluid
        const year = data.site.siteMetadata.year
        return (
          <Layout>
            <Seo
              title={`Sponsor Ascend ${year}`}
              location={`https://ascendevent.com/sponsor/`}
              description="Find out how you can sponsor this year's biggest and best Physical Therapy Rehab conference"
            />
            <SectionHeroPage
              heading="Sponsors of Ascend 2024"
              showHeadingForScreenReaderOnly={true}
              subheading="Amplify Your Brand, Accelerate Your Growth"
              image={headerSponsor}
              mediaMediumOffset={0}
              sectionHeading={<HeadingSponsorsAndExhibit />}
              ctaText="Apply Now"
              ctaLink={`${data.site.siteMetadata.sponsorshipLink}`}
              mobileSectionHeading={<HeadingSponsorMobile />}
              mobileImage={headerImageMobile}
            />
            {/*<SectionCurrentSponsors*/}
            {/*  sectionHeading={<HeadingPastSpnsrs />}*/}
            {/*  sectionHeadingMobile={<HeadingPastSpnsrsMobile />}*/}
            {/*/>*/}
            <SectionMediaHalf
              image={boothCrowd}
              videoUrl=""
              showVideo={false}
              sectionHeading=""
              textSmallOffset={0}
              textMediumOffset={0}
              textLargeOffset={1}
              textWidthMedium={5}
              mediaSmallOffset={0}
              mediaMediumOffset={0}
              mediaLargeOffset={0}
              imageWidthMedium={6}
              textLargeOrder={2}
              textMediumOrder={2}
              textSmallOrder={2}
              mediaLargeOrder={1}
              mediaMediumOrder={1}
              mediaSmallOrder={1}
              headingTag="h3"
              sectionBody="<h3>Meet Your Next Big Opportunity at Ascend</h3><p>
                    Our sponsorship opportunities are highly curated and can be customized to align with your growth and revenue objectives. When you sponsor this conference, you’ll give your brand a strategic advantage that others will miss. Network directly with your target audience while enhancing the overall attendee experience!</p>"
              ctaLink={`${data.site.siteMetadata.sponsorshipLink}`}
              ctaText="Apply Now"
            />
            <SectionTopFour />
            <SectionSponsorTypes
              sectionHeading={<HeadingSponsorsTypes />}
              sectionHeadingMobile={<HeadingSponsorMobile />}
            />
            <SectionPastSponsors
              sectionHeading={<HeadingPastSpnsrs />}
              sectionHeadingMobile={<HeadingPastSpnsrsMobile />}
            />
            <SectionMediaHalf
              image={joinTheTeam}
              videoUrl=""
              showVideo={false}
              sectionHeading=""
              textSmallOffset={0}
              textMediumOffset={0}
              textLargeOffset={1}
              textWidthMedium={5}
              mediaSmallOffset={0}
              mediaMediumOffset={0}
              mediaLargeOffset={0}
              imageWidthMedium={6}
              textLargeOrder={1}
              textMediumOrder={1}
              textSmallOrder={1}
              mediaLargeOrder={2}
              mediaMediumOrder={2}
              mediaSmallOrder={2}
              headingTag="h3"
              sectionBody="<h3>Ready to Join the Team?</h3><p>
                    Complete the form to become an Ascend 2024 sponsor, and one of our event planners will be in touch to discuss your options!</p>"
              ctaLink={`${data.site.siteMetadata.sponsorshipLink}`}
              ctaText="Apply Now"
            />
            <SectionCurrentSponsors
              sectionHeading={<HeadingPastSpnsrs />}
              sectionHeadingMobile={<HeadingPastSpnsrsMobile />}
              id="current"
            />
          </Layout>
        )
      }}
    />
  )
}

export default SponsorsPage
